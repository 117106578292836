@import 'import';
.title {
  margin-top: rem(16);
  margin-bottom: 0;
  padding-top: rem(16);

  font-size: rem(15);
  font-weight: bold;
  line-height: rem(20);
  color: $grey-light;

  border-top: 3px solid rgba(247 247 247 / 20%);
}

.subtitle {
  --tooltip-z-index: 2;

  margin-bottom: 0;
  padding-bottom: rem(16);

  font-size: rem(13);
  line-height: rem(20);
  color: $gray-410;

  :global(.perks) {
    top: 25px !important;
  }
}

.tooltipOpener {
  color: $gray-410;
  text-decoration: underline;
  background: transparent;
}
