@import 'import';
.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rows {
  font-weight: 500;
  color: $grey-light;
}

.subrows {
  padding-left: 12px;
  font-weight: 400;
  color: $gray-410;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: rem(15);
  line-height: rem(24);

  @include media-breakpoint-down(md) {
    line-height: rem(32);
  }
}
