@import 'import';
.uplift {
  border: 2px solid transparent;

  &.uplift-font {
    @include font($size: rem(13), $weight: 'normal', $line-height: rem(18));

    padding-top: rem(8);

    .price {
      @include font($size: rem(15), $weight: 'normal', $line-height: rem(24));
    }
  }

  &:focus-visible {
    border: 2px solid $white;
    outline: 2px solid $black;
  }

  .modal-link {
    min-width: 200px;
    border-bottom: 1px solid currentcolor;
  }

  .uplift__price {
    .month {
      display: inline-flex;
    }
  }

  .tooltip {
    vertical-align: text-bottom;
  }

  .hidden {
    display: none;
  }

  .UpliftTooltipIcon {
    cursor: pointer;
    width: 10px;
    height: 10px;
    margin-left: 3px;

    @include media-breakpoint-down(xxs) {
      margin: 0;
    }
  }

  .up-info-tooltip-box {
    white-space: normal;
  }
}
