@import 'import';
.root {
  position: fixed;
  z-index: 20;
  bottom: 0;
  left: 0;

  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: space-between;

  width: 100vw;
  padding: 24px 39px 20px;

  opacity: 1;
  background-color: $light-grey;
  backdrop-filter: blur(10px);
  border-top: $lightwhite;
  box-shadow: 0 -2px 8px 0 $body-color-black-opacaity;

  @media (max-width: $container-md-width) {
    z-index: 10;
  }

  @media (max-width: $container-xs-width) {
    display: block;
    padding: 17px 24px 20px;
  }

  @media (min-resolution: $zoom-more-than-250) {
    @include media-breakpoint-down(sm) {
      position: absolute;
    }
  }
}

.priceInfo {
  display: flex;
  flex: 1;
  gap: 20px;
  justify-content: space-between;

  @media (max-width: $container-xs-width) {
    display: none;
  }
}

.control {
  flex-basis: 270px;
}

.grandTotal {
  text-align: left;
}

.deposit {
  text-align: right;
}

.price {
  @include font($size: rem(17), $weight: 500, $line-height: rem(28));

  // Discount
  :global(.amount) {
    color: $red;
  }

  :global(.original-amount) {
    @include font($size: rem(13), $weight: 400, $line-height: rem(20), $color: $gray-510);
  }
}

.description {
  @include font($size: rem(11), $line-height: rem(16), $color: $grey-text);

  white-space: nowrap;
}
