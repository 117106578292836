@import 'import';
.root {
  position: absolute;
  z-index: $z-index-black-friday-rotunda;
  top: -10px;
  right: 25px;

  overflow: auto;

  .text {
    position: absolute;
    top: 39%;
    left: 27%;

    @include font($size: rem(12), $weight: bold, $color: $white, $spacing: 2.25px);
  }

  @media (max-width: $container-lg1-max-width) {
    right: 5px;
  }
}
